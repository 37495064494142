<template>
  <div class="about">

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-12 box-shadow">
          <img class="card-img-top event-banner" alt=event.name
               v-bind:src="event.image"
               data-holder-rendered="true">
          <div class="card-body">
            <div class="card-text text-lg-center">
              <span class="pl-md-1"><font-awesome-icon icon="swimmer" v-if="event.type==='Swim'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="biking" v-if="event.type==='Ride'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="running" v-if="event.type==='Run'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="walk" v-if="event.type==='Walk'"/></span>
              <span v-for="(type, idx) in event.type" :key="idx" class="pl-md-1">
                <font-awesome-icon icon="swimmer" v-if="type=='Swim'"/>
                <font-awesome-icon icon="biking" v-if="type=='Ride'"/>
                <font-awesome-icon icon="running" v-if="type=='Run'"/>
                <font-awesome-icon icon="walking" v-if="type=='Walk'"/>
              </span>
              <span class="text-capitalize pl-md-1">{{ event.name }}</span>
              <span class="text-info" v-if="isUpcomingEvent(event.from*1000)"> (sự kiện sắp diễn ra)</span>
              <span class="text-danger" v-if="isExpiredEvent(event.to*1000)"> (sự kiện đã kết thúc)</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive-sm">
      <table class="table table-striped table-hover" id="event-dashboard">
        <thead>
          <tr>
              <td>#</td>
              <td>Team Name</td>
              <td>Total Members</td>
              <td>Total Point</td>
          </tr>
        </thead>
        <tbody :class="teamStats">
          <tr v-for="(t, _id) in teamStats" :key="_id">
              <td>{{_id+1}}</td>
              <td>
                <router-link :to="`/event-dashboard/${event.slug}`">
                  {{t.name}}
                </router-link>
              </td>
              <td>{{t.total_members}}</td>
              <td>{{t.total_point}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>

import $backend from '../backend'

export default {
  name: 'TeamEventDashboard',
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      title: 'Team Event Dashboard',

      // athletes: this.getAllActivities(),
      error: '',
      code: '',
      teamStats: {},
      event: {},
      athlete: {},
      athleteStats: {},
      isJoined: false,
      athleteNameSearchInput: '',
      filterByCond: {},
      bgColorAndGenderCss: { F: 'female-rank-bg', M: 'male-rank-bg' },
      tableBgByGender: '',
      paginator: {
        currentPage: 1,
        totalPages: 1,
        limit: 10, // row per page todo: make this configurable
        count: 0
      },
      activitiesFields: ['type', 'distance', 'avg_pace', 'avg_speed', { key: 'id', label: 'Soii/Report' }]
    }
  },
  created () {
    if (localStorage.hasOwnProperty('athlete')) {
      this.athlete = JSON.parse(localStorage.getItem('athlete'))
    }

    let slug = this.$route.params.slug
    this.getEvent(slug)
    this.title = this.title + ' | ' + slug
    this.getEventStatsByTeam(slug)

    if (/^\?fbclid=/.test(location.search)) {
      location.replace(location.href.replace(/\?fbclid.+/, ''))
    }
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.kind_of_activity === 'brick') return 'table-success'
    },
    isUpcomingEvent: function (fromTs) {
      return fromTs > Date.now()
    },
    isExpiredEvent: function (toTs) {
      return toTs < Date.now()
    },
    gotoPage (page) {
      if (page < 1) {
        page = 1
      }
      if (page > this.paginator.totalPages) {
        page = this.paginator.totalPages
      }
      let limit = this.paginator.limit
      let offset = (page - 1) * limit
      let slug = this.$route.params.slug
      $backend.getEventAthlete(slug, limit, offset, this.filterByCond).then(responseData => {
        this.athletes = responseData.data.athletes
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)
        this.paginator.currentPage = page
        if (this.paginator.currentPage > this.paginator.totalPages) {
          this.paginator.currentPage = this.paginator.totalPages
        }

        let athleteID = this.athlete._id
        let isJoin = this.isJoined

        this.athletes.forEach(function (item) {
          if (item.athlete.id === athleteID) {
            isJoin = true
          }
        })
        this.isJoined = isJoin
      }).catch(error => {
        this.error = error.data.msg
      })
    },

    getEventStatsByTeam (slug) {
      let limit = this.paginator.limit
      let offset = 0
      $backend.getEventStatsByTeam(slug, limit, offset).then(responseData => {
        this.teamStats = responseData.data.team_stats
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)
      }).catch(error => {
        this.error = error.data.msg
      })
    },
    getEvent (slug) {
      $backend.getEvent(slug)
        .then(response => {
          this.event = response.data.event
          if (this.event.v2_rule === true) {
            this.activitiesFields = ['type', {
              key: 'kind_of_activity',
              'label': 'I/O'
            }, 'km', 'block', 'point', { key: 'id', label: 'Soii/Report' }]
          }
        }).catch(error => {
          this.error = error.data.msg
        })
    },
    join: function (slug) {
      if (typeof this.athlete.token === 'undefined') {
        this.$router.push({ name: 'login' })
        return
      }
      $backend.join(this.athlete, slug, this.code)
        .then(response => {
          this.$router.push({ name: 'profile' })
        }).catch(error => {
          this.error = error.data.msg
          if (this.error === 'duplicated action') {
            this.error = '^.^'
          }
          if (this.error === 'code is required to join this event') {
            this.code = 'hãy nhập code /code is required to join this event'
          }
          if (this.error === 'code mismatch') {
            this.code = 'code sai /code mismatch'
          }
        })
    },
    filter: function (sex) {
      let limit = this.paginator.limit
      let offset = 0
      this.paginator.currentPage = 1
      let slug = this.$route.params.slug
      if (sex !== '') {
        this.filterByCond = { athlete_sex: sex }
        this.tableBgByGender = this.bgColorAndGenderCss[sex]
      } else {
        this.filterByCond = {}
        this.tableBgByGender = ''
      }
      $backend.getEventAthlete(slug, limit, offset, this.filterByCond).then(responseData => {
        this.athletes = responseData.data.athletes
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)
        let athleteID = this.athlete._id
        let isJoin = this.isJoined

        this.athletes.forEach(function (item) {
          if (item.athlete.id === athleteID) {
            isJoin = true
          }
        })
        this.isJoined = isJoin
      }).catch(error => {
        this.error = error.data.msg
      })
    },
    searchByName (athleteNameSearchInput) {
      let limit = this.paginator.limit
      let offset = 0
      let slug = this.$route.params.slug
      $backend.searchAthleteInEvent(slug, athleteNameSearchInput, limit, offset).then(responseData => {
        this.athletes = responseData.data.athletes
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)
      }).catch(error => {
        if (error.data.msg) {
          this.error = error.data.msg
        }
      })
    },

    stats (activities) {
      let dict = {}
      this.event.type.map((t) => {
        dict[t] = { blocks: 0, kms: 0, points: 0 }
      })

      activities.map((act) => {
        dict[act.type].blocks += act.block
        dict[act.type].kms += act.km
        dict[act.type].points += act.point
      })
      this.athleteStats = dict
    },

    resetAthleteStats () {
      this.athleteStats = {}
    },
    report (eventId, whoBeingReport, actLink) {
      alert('In sport we trust! Take it easy lah (^.^)')

      $backend.reportActivity(eventId, whoBeingReport, actLink, this.athlete).then(responseData => {
        alert(responseData.msg)
      }).catch(error => {
        if (error.data.msg) {
          alert(error.data.msg)
        }
      })
    },
    getCert (eventID, athleteID) {
      $backend.getCert(eventID, athleteID).then(resp => {
        window.open('/api/cert/' + eventID + '/' + athleteID, '_blank')
      }).catch(err => {
        console.log(err)
      })
    },
    inspectActivity (eventId, whoBeingInspected, actLink) {
      $backend.inspectActivity(eventId, whoBeingInspected, actLink, this.athlete).then(responseData => {
        console.log(responseData.msg)
        window.open(actLink, '_blank')
      }).catch(error => {
        if (error.data.msg) {
          console.log(error.data.msg)
          // if (error.data.msg == 'unauthorized') {
          //  alert('Vui lòng đăng nhập để Soy :P')
          //  this.$router.push('/login')
          // }
        }
        window.open(actLink, '_blank')
      })
    }
  },
  computed: {
    now: function () {
      return new Date()
    }
  }
}

</script>

<style lang="scss">
ul {
  list-style-type: none;
  padding-left: 0;
}

#event-dashboard ul li {
  font-size: 10px;
}

.event-banner {
  max-width: 38%;
  align-self: center;
}
.male-rank-bg {
  color: black !important;
  background-color: azure !important;
}
.female-rank-bg {
  color: black !important;
  background-color: beige !important;
}
.overal-rank-bg {
  color: black !important;
  background-color: lightgray !important;
}
</style>
