<template>
  <div>
    <div class="round-counter-dashboard">
        <h2>Round Counter Dashboard {{this.eventSlug}}</h2>

      <input v-model="bibNumberSearchInput" placeholder="Enter bib number"/>&nbsp;
      <b-button v-on:click="searchByBibNumber()">
        <font-awesome-icon icon="search"></font-awesome-icon>
      </b-button>

      <div class="table-responsive-sm">
        <table class="table table-striped table-hover" id="event-dashboard">
          <thead>
            <tr>
                <td>Bib</td>
                <td>Name</td>
                <td><span class="text-success">Loop(s)</span></td>
                <td>Updated At</td>
            </tr>
          </thead>
          <tbody :class="counters">
            <tr v-for="(c, _id) in counters" :key="_id">
                <td>
                    <span>{{c.bib_number}}</span>
                </td>
                <td>{{c.full_name || "name_"+c.bib_number}}</td>
                <td><span class="text-success">{{c.counter}}</span></td>
                <td>{{c.updated_at * 1000 | formatTimestamp}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import $backend from '../backend'

export default {
  name: 'RoundCounterDashboard',
  metaInfo () {
    return {
      title: this.title
    }
  },

  data () {
    return {
      title: 'RoundCounterDashboard',
      eventSlug: '', // default one
      counters: {},
      error: '',
      interval: null,
      bibNumberSearchInput: ''
    }
  },
  created: function () {
    this.eventSlug = this.$route.params.slug
    this.title = this.title + ' | ' + this.eventSlug

    // Initial Load
    this.loadCountersDashboard(this.eventSlug)
    // Run every 10 seconds
    // this.interval = setInterval(function () {
    //  this.loadCountersDashboard(this.eventSlug)
    // }.bind(this), 30000)
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  },
  computed: {
  },
  methods: {
    searchByBibNumber: function () {
      if (this.bibNumberSearchInput === '') {
        alert('please input bib number')
        return
      }

      $backend.loadCounters(this.eventSlug, this.bibNumberSearchInput)
        .then(response => {
          this.counters = response.data.counters
        })
        .catch(error => {
          this.error = error.data.msg
        })
    },
    loadCountersDashboard: function (eventSlug) {
      console.log(eventSlug)

      $backend.loadCounters(eventSlug)
        .then(response => {
          this.counters = response.data.counters
        }).catch(error => {
          this.error = error.data.msg
        })
    }
  }
}

</script>

<style lang="scss">
  .profile-page .user-info {
    text-align: center;
    background: #f3f3f3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 90%;
  }

  h4 {
    margin-top: 2rem;
  }
</style>
