<template>
  <div>
    <div class="user-info">
          <div class="container">
                <div class="row">
                  <div class="row">
                      <div class="col-sm-12 col-md-10 offset-md-1">
                        <img :src="athlete.profile" class="user-img" />
                        <div class="card-body">
                          <h6>{{ athlete.firstname }} {{ athlete.lastname }}</h6>
                          <p>Account ID: {{ athlete._id }}</p>
                          <p>Email: {{ athlete.email }}</p>
                        </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-sm-6" v-if="isLoggedIn">
                          <div>
                            <b-button v-b-modal.modal-prevent-closing class="btn-warning">Edit Profile</b-button>
                            <b-modal
                                id="modal-prevent-closing"
                                ref="modal"
                                title="Edit your info"
                                @show="resetModal"
                                @hidden="resetModal"
                                @ok="handleOk"
                            >
                              <form ref="form" @submit.stop.prevent="handleSubmit">
                                <b-form-group
                                    label="Email"
                                    label-for="email-input"
                                    invalid-feedback="Email is required"
                                    :state="emailState"
                                >
                                  <b-form-input
                                      type="email"
                                      id="email-input"
                                      v-model="email"
                                      :state="emailState"
                                      required
                                  ></b-form-input>
                                </b-form-group>
                              </form>
                            </b-modal>
                          </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="nav-item" v-if="isLoggedIn">
                          <!-- update the logout link as below -->
                          <a class="btn btn-secondary" @click="logout">
                            <i>Logout</i>
                          </a>
                        </div>
                      </div>
                  </div>
                </div>
          </div>
    </div>

    <hr>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-10 offset-md-1">
          <div class="articles-toggle">
            <h5> My Challenges </h5>
            <div class="card mb-12 box-shadow" v-for="(event, idx) of events" v-bind:key="idx">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-md-4">
                    <div class="btn-group">
                      <router-link class="btn btn-light" :to="{name: 'eventDetail', params:{slug: event.slug}}">
                        <span class="text-info text-center">{{event.name}}</span>
                      </router-link>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <span class="text-muted text-sm-right">{{event.from * 1000 | formatTimestamp }} - {{event.to * 1000 | formatTimestamp}}</span>
                  </div>
                  <div class="col-md-4">
                    <div class="btn-group">
                      <router-link class="btn btn-dark" :to="{name: 'eventDashboard', params:{slug: event.slug}}">view dashboard</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $backend from '../backend'

export default {
  name: 'Profile',
  metaInfo () {
    return {
      title: this.title
    }
  },

  data () {
    return {
      title: 'Profile',
      athlete: {},
      events: [],
      error: '',

      email: '',
      emailState: null,
      submittedEmails: []
    }
  },
  created () {
    if (localStorage.hasOwnProperty('athlete')) {
      this.athlete = JSON.parse(localStorage.getItem('athlete'))
      this.title = 'Profile | ' + this.athlete.firstname + ' ' + this.athlete.lastname
      this.loadChallenge()
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.isLoggedIn
    }
  },
  methods: {
    loadChallenge () {
      $backend.getAthChallenge(this.athlete).then(responseData => {
        this.events = responseData.data
      }).catch(error => {
        this.error = error.data.msg
      })
    },
    logout () {
      localStorage.removeItem('athlete')
      this.$router.push('/login')
    },
    sync () {
      let token = this.athlete.token.access_token
      let d = new Date()
      let afterTs = d.setDate(d.getDate() - 5)
      $backend.sync(token, afterTs).then(responseData => {
      }).catch(error => {
        this.error = error.data.msg
      })
    },

    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.emailState = valid
      return valid
    },
    resetModal () {
      this.email = ''
      this.emailState = null
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted emails
      this.athlete.email = this.email
      $backend.updateProfile(this.athlete).then(responseData => {
        localStorage.setItem('athlete', JSON.stringify(responseData.data))
        alert('Profile updated!')
      }).catch(error => {
        this.error = error.data.msg
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    }
  }
}

</script>

<style lang="scss">
  .profile-page .user-info {
    text-align: center;
    background: #f3f3f3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 90%;
  }

  h4 {
    margin-top: 2rem;
  }
</style>
