<template>
  <div class="container">
    <div class="text-danger">{{error}}</div>
  </div>
</template>

<script>
import $backend from '../backend'

export default {
  name: 'webhook',
  data () {
    return {
      resources: [],
      error: ''
    }
  },
  beforeMount () {

  },
  created () {
    let code = this.$route.query.code
    let client = this.$route.query.client

    if (code && code !== 'undefined') {
      $backend.exchangeToken(client, code).then(responseData => {
        localStorage.setItem('athlete', JSON.stringify(responseData.data))
        this.$store.commit('setIsLoggedIn', true)
        this.$router.replace({ name: 'home' })
      }).catch(error => {
        this.error = error.data.msg
      })
    } else {
      this.error = 'something wrong'
    }
  },
  methods: {
  }
}

</script>

<style lang="scss">
</style>
