<template>
  <div>
    <div class="round-counter">
        <h2>Round Counter {{this.eventSlug}}</h2>

      <input v-model="bibNumberSearchInput" placeholder="Enter bib number"/>&nbsp;
      <b-button v-on:click="searchByBibNumber()">
        <font-awesome-icon icon="search"></font-awesome-icon>
      </b-button>

      <div class="table-responsive-sm">
        <table class="table table-striped table-hover" id="event-dashboard">
          <thead>
            <tr>
                <td>Bib</td>
                <td><span class="text-danger">Loop(s)</span></td>
                <td>Updated At</td>
                <td>Action</td>
            </tr>
          </thead>
          <tbody :class="counters">
            <tr v-for="(c, _id) in counters" :key="_id">
                <td>{{c.bib_number}}</td>
                <td><span class="text-danger">{{c.counter}}</span></td>
                <td>{{c.updated_at * 1000 | formatTimestamp}}</td>
                <td>
                  <b-button variant="danger" size="lg" v-on:click="countUp(eventSlug,
                    c.bib_number)">+</b-button> &nbsp;&nbsp;

                  <b-button v-on:click="countDown(eventSlug, c.bib_number)">-</b-button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import $backend from '../backend'

export default {
  name: 'RoundCounter',
  metaInfo () {
    return {
      title: this.title
    }
  },

  data () {
    return {
      title: 'RoundCounter',
      eventSlug: '', // default one
      counters: {},
      error: '',
      bibNumberSearchInput: '',
      isAuth: false
    }
  },
  created: function () {
    this.eventSlug = this.$route.params.slug
    this.title = this.title + ' | ' + this.eventSlug
    if (this.checkAuth(this.eventSlug)) {
      this.loadCountersDashboard(this.eventSlug)
    }
  },
  beforeDestroy: function () {
  },
  computed: {
  },
  methods: {
    checkAuth: function () {
      if (localStorage.hasOwnProperty('isAuthForEvent_' + this.eventSlug)) {
        this.isAuth = true
        return this.isAuth
      }

      let roundCounterEventCode = prompt('please enter round counter code for this event')
      $backend.verifyRoundCounterEventCode(this.eventSlug, roundCounterEventCode)
        .then(response => {
          alert(response.data.msg)
          this.isAuth = true
          localStorage.setItem('isAuthForEvent_' + this.eventSlug, true)
          this.loadCountersDashboard(this.eventSlug)
        })
        .catch(error => {
          alert(error.data.msg)
          this.error = error.data.msg
          this.isAuth = false
        })

      return this.isAuth
    },
    countUp: function (eventSlug, bibNumber) {
      $backend.countUp(eventSlug, bibNumber)
        .then(response => {
          alert(response.data.msg)
          // reload counter
          this.searchByBibNumber()
        })
        .catch(error => {
          alert(error.data.msg)
          this.error = error.data.msg
          this.searchByBibNumber()
        })
    },
    countDown: function (eventSlug, bibNumber) {
      let sure = confirm('Are you sure?')
      console.log(sure)

      if (sure === false) {
        return
      }

      $backend.countDown(eventSlug, bibNumber)
        .then(response => {
          alert(response.data.msg)
          // reload counter
          this.searchByBibNumber()
        })
        .catch(error => {
          alert(error.data.msg)
          this.error = error.data.msg
          this.searchByBibNumber()
        })
    },
    searchByBibNumber: function () {
      $backend.loadCounters(this.eventSlug, this.bibNumberSearchInput)
        .then(response => {
          this.counters = response.data.counters
          this.bibNumberSearchInput = ''
        })
        .catch(error => {
          this.error = error.data.msg
        })
    },
    loadCountersDashboard: function (eventSlug) {
      console.log(eventSlug)

      $backend.loadCounters(eventSlug)
        .then(response => {
          this.counters = response.data.counters
        }).catch(error => {
          this.error = error.data.msg
        })
    }
  }
}

</script>

<style lang="scss">
  .profile-page .user-info {
    text-align: center;
    background: #f3f3f3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 90%;
  }

  h4 {
    margin-top: 2rem;
  }
</style>
