<template id="banner">
  <div>
    <b-carousel
      id="carousel"
      :interval="8000"
      controls
      indicators
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
    >
        <b-carousel-slide v-for="(item, index) in carouselData" :key="index">
                 <template v-slot:img>
                    <a :href=item.externalUrl>
                      <img
                       class="block"
                       width="200"
                       height="100"
                       :src="item.imgUrl"
                       :alt="item.alt">
                    </a>
                 </template>
        </b-carousel-slide>
    </b-carousel>

  </div>
</template>

<script>

export default {
  name: 'TopBanner',
  data: function () {
    return {
      carouselData: [
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2024/07/hapii-cafe-z5638987141372_dab08c9bfd0a8f21da99772e931a52de.jpg',
          externalUrl: 'https://hapiicoffee.com.vn/collections/all?ref=lkaht',
          alt: 'Hapii Coffee'
        },
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2024/04/gbco-2024.jpg',
          externalUrl: 'https://gbco.vn/',
          alt: 'GBCO'
        },
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2024/04/nxr-2024.jpg',
          externalUrl: 'https://www.facebook.com/groups/816311259723054',
          alt: 'Nghi Xuan Runners'
        },
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2023/05/bamboo.jpg',
          externalUrl: 'https://www.facebook.com/bamboocoffee38',
          alt: 'bamboo tea and coffee'
        },
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2024/04/thanhtom-2024.jpg',
          externalUrl: 'https://www.facebook.com/thanhtom.sports',
          alt: 'Thanh Tom Sport'
        },
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2024/04/langsport-2024.jpg',
          externalUrl: 'https://langsport.net/',
          alt: 'Lang Sport'
        },
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2023/05/wao-farm.jpg',
          externalUrl: 'https://www.facebook.com/waonongnghiepthuanthien/',
          alt: 'Wao Farm'
        },
        {
          imgUrl: 'https://nghienchaybo.com/blog/wp-content/uploads/2024/04/motive-2024.webp',
          externalUrl: 'https://motive.vn/',
          alt: 'Motive Vietnam'
        }
      ]
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
  #banner {
    background-color: #efefef;
    margin:auto;
  }
  #banner a {
    color: #2c3e50;
  }
  a:hover {
    cursor: pointer;
  }
  #banner a.router-link-exact-active {
    color: #42b983;
  }
  #banner img {margin:auto}
</style>
