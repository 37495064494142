<template>
  <div class="about">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-12 box-shadow">
          <img class="card-img-top event-banner" alt=event.name
               v-bind:src="event.image"
               data-holder-rendered="true">
          <div class="card-body">
            <div class="card-text text-lg-center">
              <span class="pl-md-1"><font-awesome-icon icon="swimmer" v-if="event.type==='Swim'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="biking" v-if="event.type==='Ride'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="running" v-if="event.type==='Run'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="walk" v-if="event.type==='Walk'"/></span>
              <span v-for="(type, idx) in event.type" :key="idx" class="pl-md-1">
                <font-awesome-icon icon="swimmer" v-if="type=='Swim'"/>
                <font-awesome-icon icon="biking" v-if="type=='Ride'"/>
                <font-awesome-icon icon="running" v-if="type=='Run'"/>
                <font-awesome-icon icon="walking" v-if="type=='Walk'"/>
              </span>
              <span class="text-capitalize pl-md-1">{{ event.name }}</span>
              <span class="text-info" v-if="isUpcomingEvent(event.from*1000)"> (sự kiện sắp diễn ra)</span>
              <span class="text-danger" v-if="isExpiredEvent(event.to*1000)"> (sự kiện đã kết thúc)</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive-sm">
      <table class="table table-striped table-hover" id="event-dashboard">
        <thead>
        <tr>
          <th>
              <span>#</span>
          </th>
          <th v-if="event.levels">
              <span>Level</span>
              <span v-if="event.teams">/ Team</span>
          </th>
          <th>Avatar</th>
          <th>Tên/email</th>
          <th v-if="event.teams">Team</th>
          <th>
              <span v-if="!(event.personal_target || event.personal_target_km || event.personal_target_point|| event.personal_target_pace)">Tổng km</span>
              <span v-if="event.total_km && !event.v3_rule">%/</span>
              <span v-if="event.personal_target || event.personal_target_km || event.personal_target_point">%</span>
              <span v-if="event.personal_target_pace">Pace mục tiêu</span>
          </th>
          <th>
              <span v-if="event.rules">Tổng điểm</span>
              <span v-if="event.is_loop_count">/ Tổng vòng</span>
              <span v-if="event.v3_rule"> / Tổng ngày chạy</span>
          </th>
          <th>
              Soi
              <span v-if="event.finished_cert"> / Cert</span>
          </th>
        </tr>
        <tr>
          <td colspan="8">
              <div v-if="event.levels">
                  <b-button
                      v-on:click="filter('')"
                      class="btn btn-sm overal-rank-bg"
                      role="button"
                      aria-pressed="true"
                  >
                      Overal rank
                  </b-button>
                  &nbsp;

                  <b-button
                      v-on:click="filter('F')"
                      class="btn btn-sm female-rank-bg"
                      role="button"
                      aria-pressed="true"
                  >
                      Female rank
                  </b-button>
                  &nbsp;
                  <b-button
                      v-on:click="filter('M')"
                      class="btn btn-sm male-rank-bg"
                      role="button"
                      aria-pressed="true"
                  >
                      Male rank
                  </b-button>
              </div>

              <div v-if="event.teams">
                <hr>
                <span>Filter by team:</span>
                <select v-model="filterByTeam"
                        v-on:change="filter()"
                        class="form-select form-select-sm custom-width">
                    <option v-for="(option, idx) in event.teams"
                      :key="idx"
                      :value="option.slug">
                      {{ option.name }}
                    </option>
                  </select>
                <hr>
              </div>

            <input v-model="athleteNameSearchInput" placeholder="Enter your name"/>&nbsp;
            <b-button v-on:click="searchByName(athleteNameSearchInput)">
              <font-awesome-icon icon="search"></font-awesome-icon>
            </b-button>
          </td>
          <td v-if="event.total_km || event.target_required"></td>
        </tr>
        </thead>
        <tbody :class="tableBgByGender">
        <tr v-for="(item, _id) in athletes" :key="_id">
          <td>
              {{ (paginator.currentPage - 1) * paginator.limit + (_id + 1) }}

              <div v-if="event.v2_rule">
                <span class="text-danger"
                    v-if="
                        (event.personal_target_point && (item.athlete.total_point < item.athlete.personal_target_point)) ||
                        ((event.personal_target_km || event.personal_target) && (item.athlete.total_run_km < item.athlete.personal_target_km))                ">
                    {{event.punishment_amount || "-50k"}}
                </span>

                  <span class="text-danger" v-if="
                      event.personal_target_pace && !(event.total_point_target !== null && item.athlete.total_point >= event.total_point_target)"
                  >
                  -30k
                  </span>

              </div>

              <div v-if="event.v3_rule">
                  <!-- only for event with total_km and total_days :D -->
                  <span v-if="
                      ((event.total_km != null && item.athlete.total_run_km >= event.total_km) || item.athlete.total_run_km >= item.athlete.personal_target) ||
                      (event.total_days_target !== null && item.athlete.total_days >= event.total_days_target)
                      "
                  >
                  &#9989;
                  </span>
                  <!-- finish 2 condition -->
                  <span v-if="
                      ((event.total_km != null && item.athlete.total_run_km >= event.total_km) || item.athlete.total_run_km >= item.athlete.personal_target) &&
                      (event.total_days_target !== null && item.athlete.total_days >= event.total_days_target)"
                  >
                  &#129409;
                  </span>
                  <!-- finish 7/7 -->
                  <span v-if="
                      ((event.total_km != null && item.athlete.total_run_km >= event.total_km) || item.athlete.total_run_km >= item.athlete.personal_target) &&
                      (event.total_days_target !== null && item.athlete.total_days > event.total_days_target)"
                  >
                  &#129417;
                  </span>
                  <!-- only for event with total_km and total_days :D -->
                  <span class="text-danger" v-if="
                               !((event.total_km != null && item.athlete.total_run_km >= event.total_km) || item.athlete.total_run_km >= item.athlete.personal_target) &&
                      !(event.total_days_target !== null && item.athlete.total_days >= event.total_days_target)"
                  >
                    {{event.punishment_amount || "-50k"}}
                  </span>

                  <!-- only for event with total_km and total_days :D only for whom with 0 km -->
                  <span class="text-danger" v-if="
                      (event.total_km !== null && item.athlete.total_run_km == 0) ||
                      (item.athlete.personal_target && item.athlete.total_run_km == 0) ||
                      (event.total_days_target !== null && item.athlete.total_days == 0)"
                  >
                  &#128055;
                  </span>
              </div>
          </td>

          <td v-if="event.levels">
              <span class="fs-5" v-if="item.athlete.personal_level.includes('1')">🐥</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('2')">🐢</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('3')">🐎</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('4')">🦉</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('5')">🐐</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('6')">🦣</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('7')">🐲</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('8')">🦖</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('9')">🦍</span>

              <!--for 3d tet 2024-->
              <span class="fs-5" v-if="item.athlete.personal_level.includes('Chanh')">🐉</span>
              <span class="fs-5" v-if="item.athlete.personal_level.includes('Sinh')">🐲</span>
          </td>

          <td>
            <a v-bind:href="item.athlete.strava_link" target="_blank">
              <img v-bind:src="item.athlete.profile" width="50" height="50"/>
            </a>
          </td>
          <td>
            <p>
              <a v-bind:href="item.athlete.strava_link" target="_blank">
                {{ item.athlete.firstname + ' ' + item.athlete.lastname }}
              </a>
            </p>
            <p>
              <span>{{ item.athlete.email }}</span>
            </p>
          </td>
          <td v-if="item.athlete.team">
              <small>
                <router-link :to="`/team-event-dashboard/${event.slug}`">
                  {{item.athlete.team.name}}
                </router-link>
              </small>
          </td>
          <td v-if="event.total_km && !event.v3_rule">
            <div class="progress">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                   v-bind:aria-valuenow="100*(item.athlete.total_run_km/event.total_km) | formatNumber"
                   aria-valuemin="0" aria-valuemax="100"
                   v-bind:style="'width:'+ (item.athlete.total_run_km/event.total_km)*100 + '%'">
                {{ 100 * (item.athlete.total_run_km / event.total_km) | formatNumber }}%
              </div>
            </div>
          </td>

          <td v-if="item.athlete.personal_target || item.athlete.personal_target_km || item.athlete.personal_target_point || item.athlete.personal_target_pace">
            <div class="progress" v-if="item.athlete.personal_target">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                   v-bind:aria-valuenow="100*(item.athlete.total_run_km/item.athlete.personal_target) | formatNumber"
                   aria-valuemin="0" aria-valuemax="100"
                   v-bind:style="'width:'+ (item.athlete.total_run_km/item.athlete.personal_target)*100 + '%'"
              >
                {{ item.athlete.total_run_km }}/{{ item.athlete.personal_target }} =
                {{ 100 * (item.athlete.total_run_km / item.athlete.personal_target) | formatNumber }}%
              </div>
            </div>
            <!-- personal_target_km-->
            <div class="progress" v-if="item.athlete.personal_target_km">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                   v-bind:aria-valuenow="100*(item.athlete.total_run_km/item.athlete.personal_target_km) | formatNumber"
                   aria-valuemin="0" aria-valuemax="100"
                   v-bind:style="'width:'+ (item.athlete.total_run_km/item.athlete.personal_target_km)*100 + '%'"
              >
                {{ item.athlete.total_run_km }}/{{ item.athlete.personal_target_km }} =
                {{ 100 * (item.athlete.total_run_km / item.athlete.personal_target_km) | formatNumber }}%
              </div>
            </div>
            <!-- personal_target_point-->
            <div class="progress" v-if="item.athlete.personal_target_point">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                   v-bind:aria-valuenow="100*(item.athlete.total_point/item.athlete.personal_target_point) | formatNumber"
                   aria-valuemin="0" aria-valuemax="100"
                   v-bind:style="'width:'+ (item.athlete.total_point/item.athlete.personal_target_point)*100 + '%'"
              >
                {{ item.athlete.total_point }}/{{ item.athlete.personal_target_point }} =
                {{ 100 * (item.athlete.total_point / item.athlete.personal_target_point) | formatNumber }}%
              </div>
            </div>
            <!-- personal_target_pace -->
            <div v-if="item.athlete.personal_target_pace">
                <!-- TODO need to set delta from event.personal_target_pace_delta -->
                {{item.athlete.personal_target_pace}} (+- 6s)
            </div>
          </td>

          <td v-if="!item.athlete.personal_target && !item.athlete.personal_target_km && !item.athlete.personal_target_point && !item.athlete.personal_target_pace">
              {{ item.athlete.total_run_km }}
          </td>

          <td>
              <span v-if="event.rules">
                  {{ item.athlete.total_point }}
              </span>
              <span v-if="event.v3_rule"> / {{ item.athlete.total_days}}</span>
          </td>
          <td>
            <div>
              <b-button
                v-b-modal="'modal-' + item.athlete.id"
              >
                Soi
              </b-button>
              &nbsp;
              <b-button v-on:click="getCert(event.id, item.athlete.id)" v-if="event.finished_cert"
                                                                    class="btn btn-info"
              >
                Cert
              </b-button>
              <b-modal size="lg" :id="'modal-'+item.athlete.id" @hide="resetAthleteStats" title="Soi Tracklog">
                <b-container fluid>
                  <b-card class="text-center">
                    <b-card-text v-if="event.levels">
                      <small class="text-primary">
                        {{ item.athlete.personal_level.toUpperCase() }}
                      </small>
                    </b-card-text>

                    <b-card-text>
                      <small class="text-danger">
                        Total point
                        <span v-if="event.is_loop_count">/ Tổng vòng </span> :

                        {{ item.athlete.total_point }}
                      </small>
                    </b-card-text>
                    <b-button v-on:click="stats(item.athlete.activities)" v-if="event.v2_rule">
                      <font-awesome-icon icon="chart-pie"></font-awesome-icon>
                    </b-button>
                    <b-card-text class="text-dark">
                      <ul>
                        <li v-bind:key="t" v-for="(stat, t) in athleteStats">
                          <span>{{ t }}: </span> {{ Math.round(stat.kms) }} kms | {{ stat.points }} points
                        </li>
                      </ul>
                    </b-card-text>

                  </b-card>
                  <b-table
                      responsive
                      hover
                      table-striped
                      striped
                      small
                      :items="item.athlete.activities"
                      :fields="activitiesFields"
                      :tbody-tr-class="rowClass"
                  >
                    <template #cell(type)="type">
                      <font-awesome-icon icon="swimmer" v-if="type.value=='Swim'"/>

                      <font-awesome-icon icon="biking" v-if="type.value=='Ride'"/>

                      <font-awesome-icon icon="vr-cardboard" v-if="type.value=='VirtualRide'"/>
                      <font-awesome-icon icon="biking" v-if="type.value=='VirtualRide'"/>

                      <font-awesome-icon icon="running" v-if="type.value=='Run'"/>

                      <font-awesome-icon icon="vr-cardboard" v-if="type.value=='VirtualRun'"/>
                      <font-awesome-icon icon="running" v-if="type.value=='VirtualRun'"/>

                      <font-awesome-icon icon="walking" v-if="type.value=='Walk'"/>

                      <span v-if="type.value=='Brick2'">2 </span>
                      <font-awesome-icon icon="puzzle-piece" v-if="type.value=='Brick2'"/>

                      <span v-if="type.value=='Brick3'">3</span>
                      <font-awesome-icon icon="puzzle-piece" v-if="type.value=='Brick3'"/>
                    </template>

                    <template #cell(kind_of_activity)="kind_of_activity">
                      <font-awesome-icon icon="cloud-sun" v-if="kind_of_activity.value=='outdoor'"/>
                      <font-awesome-icon icon="home" v-if="kind_of_activity.value == 'indoor'"/>
                      <font-awesome-icon icon="bars" v-if="kind_of_activity.value == 'brick'"/>
                    </template>

                    <template #cell()="data">
                      <span>{{ data.value }}</span>
                    </template>
                    <template #cell(id)="id">
                      <a @click="inspectActivity(event.id, item.athlete.id, 'https://www.strava.com/activities/'+id.value)">
                        &#128270;
                      </a>
                      &nbsp;
                      <a @click="report(event.id, item.athlete.id, 'https://www.strava.com/activities/'+id.value)" target="_blank">
                        &#128681;
                      </a>
                    </template>
                  </b-table>
                </b-container>
              </b-modal>
            </div>
          </td>

        </tr>
        </tbody>
      </table>

    </div>

    <div class="row center text-center align-content-center pagination text-primary">
      <div>
        <button v-on:click="gotoPage(paginator.currentPage-1)">
          <font-awesome-icon icon="arrow-left"/>
        </button>
        <button v-on:click="gotoPage(1)">
          <font-awesome-icon icon="star"/>
        </button>
        <button v-on:click="gotoPage(paginator.currentPage+1)">
          <font-awesome-icon icon="arrow-right"/>
        </button>
      </div>
      <div class="text-lg-center text-muted">Page {{ paginator.currentPage }}/ {{ paginator.totalPages }}</div>
    </div>
  </div>
</template>

<script>

import $backend from '../backend'

export default {
  name: 'EventDashboard',
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      title: 'Event Dashboard',

      // athletes: this.getAllActivities(),
      error: '',
      code: '',
      athletes: {},
      event: {},
      athlete: {},
      athleteStats: {},
      isJoined: false,
      athleteNameSearchInput: '',
      filterByCond: {},
      filterByTeam: '',
      bgColorAndGenderCss: { F: 'female-rank-bg', M: 'male-rank-bg' },
      tableBgByGender: '',
      paginator: {
        currentPage: 1,
        totalPages: 1,
        limit: 10, // row per page todo: make this configurable
        count: 0
      },
      activitiesFields: ['type', 'distance', 'avg_pace', 'avg_speed', { key: 'id', label: 'Soii/Report' }]
    }
  },
  created () {
    if (localStorage.hasOwnProperty('athlete')) {
      this.athlete = JSON.parse(localStorage.getItem('athlete'))
    }

    let slug = this.$route.params.slug
    this.getEvent(slug)
    this.title = this.title + ' | ' + slug
    this.getEventAthlete(slug)

    if (/^\?fbclid=/.test(location.search)) {
      location.replace(location.href.replace(/\?fbclid.+/, ''))
    }
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.kind_of_activity === 'brick') return 'table-success'
    },
    isUpcomingEvent: function (fromTs) {
      return fromTs > Date.now()
    },
    isExpiredEvent: function (toTs) {
      return toTs < Date.now()
    },
    gotoPage (page) {
      if (page < 1) {
        page = 1
      }
      if (page > this.paginator.totalPages) {
        page = this.paginator.totalPages
      }
      let limit = this.paginator.limit
      let offset = (page - 1) * limit
      let slug = this.$route.params.slug
      $backend.getEventAthlete(slug, limit, offset, this.filterByCond).then(responseData => {
        this.athletes = responseData.data.athletes
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)
        this.paginator.currentPage = page
        if (this.paginator.currentPage > this.paginator.totalPages) {
          this.paginator.currentPage = this.paginator.totalPages
        }

        let athleteID = this.athlete._id
        let isJoin = this.isJoined

        this.athletes.forEach(function (item) {
          if (item.athlete.id === athleteID) {
            isJoin = true
          }
        })
        this.isJoined = isJoin
      }).catch(error => {
        this.error = error.data.msg
      })
    },

    getEventAthlete (slug) {
      let limit = this.paginator.limit
      let offset = 0
      $backend.getEventAthlete(slug, limit, offset).then(responseData => {
        this.athletes = responseData.data.athletes
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)

        let isJoin = this.isJoined
        let athleteID = this.athlete._id
        this.athletes.forEach(function (item) {
          if (item.athlete.id === athleteID) {
            isJoin = true
          }
        })
        this.isJoined = isJoin
      }).catch(error => {
        this.error = error.data.msg
      })
    },
    getEvent (slug) {
      $backend.getEvent(slug)
        .then(response => {
          this.event = response.data.event
          if (this.event.v2_rule === true) {
            this.activitiesFields = [
              'type',
              {
                key: 'kind_of_activity',
                label: 'I/O'
              },
              {
                key: 'start_date_local',
                label: 'Date time'
              },

              'km',
              'block',
              'point',
              { key: 'id', label: 'Soii/Report' }
            ]
          }
        }).catch(error => {
          this.error = error.data.msg
        })
    },
    join: function (slug) {
      if (typeof this.athlete.token === 'undefined') {
        this.$router.push({ name: 'login' })
        return
      }
      $backend.join(this.athlete, slug, this.code)
        .then(response => {
          this.$router.push({ name: 'profile' })
        }).catch(error => {
          this.error = error.data.msg
          if (this.error === 'duplicated action') {
            this.error = '^.^'
          }
          if (this.error === 'code is required to join this event') {
            this.code = 'hãy nhập code /code is required to join this event'
          }
          if (this.error === 'code mismatch') {
            this.code = 'code sai /code mismatch'
          }
        })
    },
    filter: function (sex) {
      let limit = this.paginator.limit
      let offset = 0
      this.paginator.currentPage = 1
      let slug = this.$route.params.slug
      if (sex !== '') {
        this.filterByCond = { athlete_sex: sex }
        this.tableBgByGender = this.bgColorAndGenderCss[sex]
      } else {
        this.filterByCond = {}
        this.tableBgByGender = ''
      }
      if (this.filterByTeam !== '') {
        this.filterByCond = { team_slug: this.filterByTeam }
        // clear this val after set to cond
        this.filterByTeam = ''
      }

      $backend.getEventAthlete(slug, limit, offset, this.filterByCond).then(responseData => {
        this.athletes = responseData.data.athletes
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)
        let athleteID = this.athlete._id
        let isJoin = this.isJoined

        this.athletes.forEach(function (item) {
          if (item.athlete.id === athleteID) {
            isJoin = true
          }
        })
        this.isJoined = isJoin
      }).catch(error => {
        this.error = error.data.msg
      })
    },
    searchByName (athleteNameSearchInput) {
      let limit = this.paginator.limit
      let offset = 0
      let slug = this.$route.params.slug
      $backend.searchAthleteInEvent(slug, athleteNameSearchInput, limit, offset).then(responseData => {
        this.athletes = responseData.data.athletes
        this.paginator.count = responseData.data.count
        this.paginator.totalPages = Math.ceil(this.paginator.count / this.paginator.limit)
      }).catch(error => {
        if (error.data.msg) {
          this.error = error.data.msg
        }
      })
    },

    stats (activities) {
      let dict = {}
      this.event.type.map((t) => {
        dict[t] = { blocks: 0, kms: 0, points: 0 }
      })

      activities.map((act) => {
        dict[act.type].blocks += act.block
        dict[act.type].kms += act.km
        dict[act.type].points += act.point
      })
      this.athleteStats = dict
    },

    resetAthleteStats () {
      this.athleteStats = {}
    },
    report (eventId, whoBeingReport, actLink) {
      alert('In sport we trust! Take it easy lah (^.^)')

      $backend.reportActivity(eventId, whoBeingReport, actLink, this.athlete).then(responseData => {
        alert(responseData.msg)
      }).catch(error => {
        if (error.data.msg) {
          alert(error.data.msg)
        }
      })
    },
    getCert (eventID, athleteID) {
      $backend.getCert(eventID, athleteID).then(resp => {
        window.open('/api/cert/' + eventID + '/' + athleteID, '_blank')
      }).catch(err => {
        console.log(err)
      })
    },
    inspectActivity (eventId, whoBeingInspected, actLink) {
      $backend.inspectActivity(eventId, whoBeingInspected, actLink, this.athlete).then(responseData => {
        console.log(responseData.msg)
        window.open(actLink, '_blank')
      }).catch(error => {
        if (error.data.msg) {
          console.log(error.data.msg)
          // if (error.data.msg == 'unauthorized') {
          //  alert('Vui lòng đăng nhập để Soy :P')
          //  this.$router.push('/login')
          // }
        }
        window.open(actLink, '_blank')
      })
    }
  },
  computed: {
    now: function () {
      return new Date()
    }
  }
}

</script>

<style lang="scss">
ul {
  list-style-type: none;
  padding-left: 0;
}

#event-dashboard ul li {
  font-size: 10px;
}

.event-banner {
  max-width: 38%;
  align-self: center;
}
.male-rank-bg {
  color: black !important;
  background-color: azure !important;
}
.female-rank-bg {
  color: black !important;
  background-color: beige !important;
}
.overal-rank-bg {
  color: black !important;
  background-color: lightgray !important;
}

select.custom-width {
  display: inline-block;
  width: 300px;
}
</style>
