<template>
    <div id="home">
        <div class="row">
            <div class="col-md-12">
                <p class="h3">New Activities</p>
            </div>
        </div>
        <div class="col-md-12" >
            <div class="table-responsive-sm">
                <table class="table table-striped table-hover text-left"
                    id="top-latest-athletes">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Athlete</td>
                            <td>Activity Name</td>
                            <td>Type</td>
                            <td>Km</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ath, _id) in topAthletes" :key="_id">
                            <td>
                                {{_id+1}}
                            </td>
                            <td>
                                <a v-bind:href="ath.strava_link" target="_blank">
                                    <img v-bind:src="ath.profile" width="50" height="50"/>&nbsp;<span>{{ath.name}}</span>
                                </a>
                            </td>
                            <td>
                                <a v-bind:href="ath.latest_activity.strava_activity_link" target="_blank">
                                    <span>{{ath.latest_activity.name}}</span>
                                </a>
                            </td>
                            <td>{{ath.latest_activity.type}}</td>
                            <td>{{ath.latest_activity.km || 0}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <hr>

        <div class="row">
            <!-- start event list -->
            <div class="col-sm-12">
                <p class="h3">Top events</p>
            </div>

            <div class="col-sm-4" v-for="(event, idx) of events" v-bind:key="idx">
                <router-link class="btn btn-light" :to="{name: 'eventDetail', params:{slug: event.slug}}">
                    <div :class="getEventCssClass(event)" style="width: 18rem;">
                        <img class="card-img-top" alt=event.name
                             v-bind:src="event.image"
                             data-holder-rendered="true">
                        <div class="card-body">
                            <div class="row d-flex text-lg-center">
                                <span class="text-white" v-if="isUpcomingEvent(event.from*1000)">sự kiện sắp diễn ra</span>
                                <span class="text-danger" v-if="isExpiredEvent(event.to*1000)">sự kiện đã kết thúc</span>
                            </div>
                            <div class="card-text text-lg-center">

                                <span class="pl-md-1"><font-awesome-icon icon="swimmer" v-if="event.type==='Swim'"/></span>
                                <span class="pl-md-1"><font-awesome-icon icon="biking" v-if="event.type==='Ride'"/></span>
                                <span class="pl-md-1"><font-awesome-icon icon="running" v-if="event.type==='Run'"/></span>
                                <span v-for="(type, idx) in event.type" :key="idx" class="pl-md-1">
                                    <font-awesome-icon icon="swimmer" v-if="type=='Swim'"/>
                                        <font-awesome-icon icon="biking" v-if="type=='Ride'"/>
                                            <font-awesome-icon icon="running" v-if="type=='Run'"/>
                                                <font-awesome-icon icon="walking" v-if="type=='Walk'"/>
                                </span>
                                <span class="text-capitalize pl-md-1">{{event.name}}</span>
                            </div>
                            <div class="row d-flex">
                                <div class="col-md-12">
                                    <span class="text-sm-right">{{event.from * 1000 | formatTimestamp }} - {{event.to * 1000 | formatTimestamp}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <!-- end event list -->
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="text-danger">
                    {{error}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $backend from '../backend'

export default {
  name: 'Home',
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      title: 'NCB Home',
      athlete: {},
      topAthletes: [],
      events: [],
      error: '',
      code: ''
    }
  },
  created () {
    if (localStorage.hasOwnProperty('athlete')) {
      this.athlete = JSON.parse(localStorage.getItem('athlete'))
    }

    this.loadTopAthletes()

    this.loadEvents()
  },
  methods: {
    loadTopAthletes: function () {
      // get top 5 athletes
      $backend.getTopAthletes(0, 5).then(
        response => {
          this.topAthletes = response.data.athletes
        }
      ).catch(
        error => {
          this.error = error.data.msg
        }
      )
      this.topAthletes = []
    },
    loadEvents: function () {
      $backend.getEvents(0, 6).then(response => {
        this.events = response.data.events
      }).catch(error => {
        this.error = error.data.msg
      })
    },
    view: function (slug) {
      this.$router.push({ name: 'eventDetail', params: { slug: slug } })
    },
    isUpcomingEvent: function (fromTs) {
      return fromTs > Date.now()
    },
    isExpiredEvent: function (toTs) {
      return toTs < Date.now()
    },
    getEventCssClass (event) {
      let css = 'card mb-6 box-shadow bg-light'

      if (this.isExpiredEvent(event.to * 1000)) {
        css += 'text-white bg-secondary'
        return css
      }
      if (this.isUpcomingEvent(event.from * 1000)) {
        css += 'text-white bg-info'
        return css
      }
      return css
    }
  },
  computed: {
    now: function () {
      return new Date()
    }
  }
}

</script>

<style lang="scss">
.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}
</style>
