import axios from 'axios'

let $axios = axios.create({
  baseURL: '/api/',
  port: process.env.APP_PORT,
  timeout: 20000,
  headers: { 'Content-Type': 'application/json' }
})

// Request Interceptor
$axios.interceptors.request.use(function (config) {
  return config
})

// Response Interceptor to handle and log errors
$axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // Handle Error
  return Promise.reject(error.response)
})

export default {
  verifyRoundCounterEventCode(eventSlug, code) {
    let url = 'round-counter/' + eventSlug + '/auth'
    let payload = {
      'code': code
    }
    return $axios.post(
      url,
      payload
    )
  },
  countUp (eventSlug, bibNumber) {
    let url = 'round-counter/'+eventSlug
    let payload = {
        'delta': 0.5,
      }

    if (typeof(bibNumber) != 'undefined' && bibNumber != "") {
      payload['bib_number'] = bibNumber
    }

    // TODO move this secret to const or config file
    return $axios.put(
      url,
      payload,
      {
        headers: {
          'x-ncb-secret-token':'XNCBSecr3tHTW@F@RM@2024!'
        }
      }
    )
  },
  countDown (eventSlug, bibNumber) {
    let url = 'round-counter/'+eventSlug
    let payload = {
        'delta': -0.5,
      }

    if (typeof(bibNumber) != 'undefined' && bibNumber != "") {
      payload['bib_number'] = bibNumber
    }

    // TODO move this secret to const or config file
    return $axios.put(
      url,
      payload,
      {
        headers: {
          'x-ncb-secret-token':'XNCBSecr3tHTW@F@RM@2024!'
        }
      }
    )
  },

  loadCounters (eventSlug, bibNumber) {
    let url = 'round-counter/'+eventSlug

    if (typeof(bibNumber) != 'undefined' && bibNumber != "") {
      url += "?bib_number="+bibNumber
    }
    return $axios.get(url)
      .then(resp => resp.data)
  },

  fetchResource () {
    return $axios.get('resource/xxx')
      .then(response => response.data)
  },

  fetchSecureResource () {
    return $axios.get('secure-resource/zzz')
      .then(response => response.data)
  },

  exchangeToken (client, code) {
    if (client === 'strava') {
      return $axios.get('strava/exchange-token/' + code)
        .then(response => response.data)
    }
    return null
  },

  sync (token, afterTs) {
    return {}
  },

  getAthChallenge (athlete) {
    return $axios.get(
      'athlete/event/' + athlete._id,
      {
        headers: { token: athlete.token.access_token }
      }
    ).then(response => response.data)
  },

  refreshToken (athlete) {
    return $axios.put(
      'athlete/auth/refresh-token',
      {
        'athlete_id': athlete._id,
        'refresh_token': athlete.token.refresh_token
      }
    ).then(response => response.data)
  },

  join (athlete, slug, opt) {
    // TODO validate in this axios proxy
    let params = {
      'athlete_id': athlete._id,
      'token': athlete.token.access_token
    }
    if (opt['code']) {
      params['code'] = opt.code
    }
    if (opt['personal_target']) {
      params['personal_target'] = opt.personal_target
    }
    if (opt['personal_target_pace']) {
      params['personal_target_pace'] = opt.personal_target_pace
    }
    if (opt['personal_level']) {
      params['personal_level'] = opt.personal_level
    }
    if (opt['team_to_join']) {
      params['team_to_join'] = opt.team_to_join
    }

    return $axios.post('event/' + slug + '/join', params)
      .then(response => response.data)
  },

  getTopAthletes (offset, limit) {
    return $axios.get('athletes/top' + '?limit=' + limit + '&offset=' + offset).then(response => response.data)
  },

  getEvents (offset, limit) {
    return $axios.get('events' + '?limit=' + limit + '&offset=' + offset)
      .then(response => response.data)
  },

  getEvent (slug) {
    return $axios.get('event/' + slug)
      .then(response => response.data)
  },

  getEventStatsByTeam (slug, limit, offset) {
    return $axios.get('event/team-stats/' + slug + '?limit=' + limit + '&offset=' + offset)
      .then(response => response.data)
  },

  getEventAthlete (slug, limit, offset, cond) {
    if (cond && cond.hasOwnProperty('athlete_sex')) {
      return $axios
        .get('event/athlete/' + slug + '?athlete_sex=' + cond['athlete_sex'] + '&limit=' + limit + '&offset=' + offset)
        .then(response => response.data)
    }

    if (cond && cond.hasOwnProperty('team_slug')) {
      return $axios
        .get('event/athlete/' + slug + '?team_slug=' + cond['team_slug'] + '&limit=' + limit + '&offset=' + offset)
        .then(response => response.data)
    }

    return $axios.get('event/athlete/' + slug + '?limit=' + limit + '&offset=' + offset)
      .then(response => response.data)
  },
  searchAthleteInEvent (slug, searchAthleteInEvent, limit, offset) {
    return $axios.get('event/athlete/' + slug + '?limit=' + limit + '&offset=' + offset + '&athlete_name=' + searchAthleteInEvent)
      .then(response => response.data)
  },
  updateProfile (athlete) {
    return $axios.post('athlete/profile/' + athlete._id, {
      'token': athlete.token.access_token,
      'athlete': athlete
    })
      .then(response => response.data)
  },
  reportActivity (eventId, whoBeingReport, actLink, athlete) {
    return $axios.post('athlete/report-activity', {
      'event_id': eventId,
      'reporter_id': athlete._id,
      'who_being_report': whoBeingReport,
      'token': athlete.token.access_token,
      'act_link': actLink
    })
      .then(response => response.data)
  },
  inspectActivity (eventId, whoBeingInspected, actLink, athlete) {
    return $axios.post('athlete/inspect-activity', {
      'event_id': eventId,
      'inspector_id': athlete._id,
      'who_being_inspected': whoBeingInspected,
      'token': athlete.token.access_token,
      'act_link': actLink
    })
      .then(response => response.data)
  },
  getCert (eventID, athleteID) {
    return $axios.get('cert/' + eventID + '/' + athleteID).then(resp => resp.data)
  }

}
