<template>
    <div class="about">
        <h1>Exchange Token</h1>
        <p>{{error}}</p>
    </div>
</template>

<script>
export default {
  name: 'exchangeToken',
  data () {
    return {
      resources: [],
      error: ''
    }
  },
  methods: {

  }
}

</script>

<style lang="scss">
</style>
