<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-12 box-shadow">
          <img class="card-img-top event-banner" alt=event.name
               v-bind:src="event.image"
               data-holder-rendered="true">
          <div class="card-body">

            <div class="card-text text-lg-center">
              <span class="pl-md-1"><font-awesome-icon icon="swimmer" v-if="event.type==='Swim'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="biking" v-if="event.type==='Ride'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="running" v-if="event.type==='Run'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="walk" v-if="event.type==='Walk'"/></span>
              <span v-for="(type, idx) in event.type" :key="idx" class="pl-md-1">
                <font-awesome-icon icon="swimmer" v-if="type=='Swim'"/>
                <font-awesome-icon icon="biking" v-if="type=='Ride'"/>
                <font-awesome-icon icon="running" v-if="type=='Run'"/>
                <font-awesome-icon icon="walking" v-if="type=='Walk'"/>
              </span>
              <span class="text-capitalize pl-md-1">{{ event.name }}</span>
              <span class="text-info" v-if="isUpcomingEvent(event.from*1000)"> (sự kiện sắp diễn ra)</span>
              <span class="text-danger" v-if="isExpiredEvent(event.to*1000)"> (sự kiện đã kết thúc)</span>
            </div>

            <div class="align-content-center mt-2 mb-2">
              <div v-if="event.rules" class="text-lg-center text-primary">
                Cách tính điểm:
                <ul>
                  <li v-for="r in event.rules" :key="r.description">
                    <div v-html="r.description"></div>
                  </li>
                </ul>
              </div>
              <p class="card-text text-md-center" v-html="event.description">
              </p>
              <div class="text-info text-center">{{ event.from * 1000 | formatTimestamp }} -
                {{ event.to * 1000 | formatTimestamp }}
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <input v-if="event.code_required" v-model="code" placeholder="nhập code" class="mr-md-2">

                <div v-if="event.levels">
                    Hãy chọn level:&nbsp;
                    <select v-model="personal_level">
                      <option v-for="(option, idx) in event.levels"
                        :key="idx"
                        :value="option">
                        {{ option }}
                      </option>
                    </select>
                </div>

                <div v-if="event.teams">
                    Hãy chọn team:&nbsp;
                    <select v-model="team_to_join">
                      <option v-for="team in event.teams"
                        :key="team.slug"
                        :value="team.slug">
                        {{ team.name }}
                      </option>
                    </select>
                </div>

                <input type="number"
                       v-if="event.target_required"
                       v-model="personal_target"
                       placeholder="nhập mục tiêu cá nhân" class="mr-md-2">

                <input type="string"
                       v-if="event.personal_target_pace"
                       v-model="personal_target_pace"
                       placeholder="nhập PACE mục tiêu cá nhân (vdu: 5p30)" class="mr-md-2">

                <button type="button" class="btn btn-sm btn-info" v-on:click="join(event.slug)">Tham gia/ Join</button>
                <span class="text-danger pl-md-2">{{ error }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <router-link class="btn btn-sm btn-primary" :to="{name:
                    'eventDashboard', params:{slug: event.slug}}" v-if="!event.teams">
                  Xem bảng xếp hạng/ View dashboard
                </router-link>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <router-link v-if="event.teams" class="btn btn-sm btn-warning" :to="{name: 'teamEventDashboard', params:{slug: event.slug}}">
                  Team Event Dashboard
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $backend from '../backend'

export default {
  name: 'EventDetail',
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      title: 'Event Detail',
      athlete: {},
      event: {},
      error: '',
      code: '',
      personal_target: '',
      personal_target_pace: '',
      personal_level: '',
      team_to_join: ''
    }
  },
  created () {
    if (!localStorage.hasOwnProperty('athlete')) {
      this.$router.push({ name: 'login' })
    }

    this.athlete = JSON.parse(localStorage.getItem('athlete'))
    let slug = this.$route.params.slug
    this.title = this.title + ' | ' + slug
    this.getEvent(slug)
  },
  methods: {
    isUpcomingEvent: function (fromTs) {
      return fromTs > Date.now()
    },
    isExpiredEvent: function (toTs) {
      return toTs < Date.now()
    },
    getEvent (slug) {
      $backend.getEvent(slug)
        .then(response => {
          this.event = response.data.event
        }).catch(error => {
          this.error = error.data.msg
        })
    },
    join: async function (slug) {
      if (this.athlete.token === 'undefined') {
        this.$router.push({ name: 'login' })
        return
      }
      let opt = {}
      if (this.event.code_required) {
        opt.code = this.code
      }
      if (this.event.target_required) {
        opt.personal_target = this.personal_target
      }

      // validate personal level
      if (this.event.levels && (this.event.levels.length > 0)) {
        if (this.personal_level === '') {
          if (this.event.levels.length === 1) {
            this.personal_level = this.event.levels[0]
          } else {
            alert('Please select your level')
            return
          }
        }
        opt.personal_level = this.personal_level
      }

      if (this.event.teams && (this.event.teams.length > 0)) {
        if (this.team_to_join === '') {
          alert('Please select your team')
          return
        }
        opt.team_to_join = this.team_to_join
      }

      if (this.event.personal_target_pace) {
        if (this.personal_target_pace === '') {
          alert('Hãy nhập pace target của bạn')
          return
        }

        let pattern = /^\dp\d\d$/
        if (!pattern.test(this.personal_target_pace)) {
          alert('Hãy nhâp pace target hợp lệ, ví dụ:  5p30 hoặc 5p00')
          return
        }

        opt.personal_target_pace = this.personal_target_pace
      }

      let forceLogInAgain = false
      if (this.athlete.token.expires_at < Math.round(new Date().getTime() / 1000)) {
        console.log('token expired, calling refreshToken')

        forceLogInAgain = await $backend.refreshToken(this.athlete).then(response => {
          console.log(response)
          this.athlete.token = response.token
          localStorage.setItem('athlete', JSON.stringify(this.athlete))

          return false
        }).catch(error => {
          console.log('error', error)
          // force user login again
          if (error.data.message === 'unauthorized') {
            return true
          }
        })
      }

      if (forceLogInAgain) {
        this.$router.push({ name: 'login' })
        return
      }

      await $backend.join(this.athlete, slug, opt)
        .then(response => {
          if (this.event.teams && this.event.teams.length > 0) {
            this.$router.push({ name: 'teamEventDashboard', params: { slug: slug } })
          } else {
            this.$router.push({ name: 'eventDashboard', params: { slug: slug } })
          }
        }).catch(error => {
          console.log(this.event.teams)

          this.error = error.data.msg
          if (this.error === 'duplicated action') {
            if (this.event.teams && this.event.teams.length > 0) {
              this.$router.push({ name: 'teamEventDashboard', params: { slug: slug } })
            } else {
              this.$router.push({ name: 'eventDashboard', params: { slug: slug } })
            }
          }

          if (this.error === 'code mismatch') {
            this.error = 'sai code/ code mismatch'
          }

          if (this.error === 'unauthorized') {
            this.$router.push({ name: 'login' })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.event-banner {
  max-width: 50%;
  align-self: center;
}
</style>
