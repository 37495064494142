<template>
  <div id="events">
    <div class="row">
      <div class="col-sm-3" v-for="(event, idx) of events" v-bind:key="idx">
         <router-link class="btn btn-light" :to="{name: 'eventDetail', params:{slug: event.slug}}">
        <div :class="getEventCssClass(event)" style="width: 16rem;">
            <img class="card-img-top" alt=event.name
               v-bind:src="event.image"
               data-holder-rendered="true">
          <div class="card-body">
            <div class="row d-flex text-lg-center">
                <span class="text-white" v-if="isUpcomingEvent(event.from*1000)">sự kiện sắp diễn ra</span>
                <span class="text-danger" v-if="isExpiredEvent(event.to*1000)">sự kiện đã kết thúc</span>
            </div>
            <div class="card-text text-lg-center">

              <span class="pl-md-1"><font-awesome-icon icon="swimmer" v-if="event.type==='Swim'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="biking" v-if="event.type==='Ride'"/></span>
              <span class="pl-md-1"><font-awesome-icon icon="running" v-if="event.type==='Run'"/></span>
              <span v-for="(type, idx) in event.type" :key="idx" class="pl-md-1">
                <font-awesome-icon icon="swimmer" v-if="type=='Swim'"/>
                <font-awesome-icon icon="biking" v-if="type=='Ride'"/>
                <font-awesome-icon icon="running" v-if="type=='Run'"/>
                <font-awesome-icon icon="walking" v-if="type=='Walk'"/>
              </span>
              <span class="text-capitalize pl-md-1">{{event.name}}</span>
            </div>
            <div class="row d-flex">
                 <div class="col-md-12">
                  <span class="text-sm-right">{{event.from * 1000 | formatTimestamp }} - {{event.to * 1000 | formatTimestamp}}</span>
                </div>
            </div>
          </div>
        </div>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="text-danger">
          {{error}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $backend from '../backend'

export default {
  name: 'Events',
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      title: 'NCB Events',
      athlete: {},
      events: [],
      error: '',
      code: ''
    }
  },
  created () {
    if (localStorage.hasOwnProperty('athlete')) {
      this.athlete = JSON.parse(localStorage.getItem('athlete'))
    }

    this.loadEvents()
  },
  methods: {
    loadEvents: function () {
      $backend.getEvents(0, 20).then(response => {
        this.events = response.data.events
      }).catch(error => {
        this.error = error.data.msg
      })
    },
    view: function (slug) {
      this.$router.push({ name: 'eventDetail', params: { slug: slug } })
    },
    isUpcomingEvent: function (fromTs) {
      return fromTs > Date.now()
    },
    isExpiredEvent: function (toTs) {
      return toTs < Date.now()
    },
    getEventCssClass (event) {
      let css = 'card mb-6 box-shadow bg-light'

      if (this.isExpiredEvent(event.to * 1000)) {
        css += 'text-white bg-secondary'
        return css
      }
      if (this.isUpcomingEvent(event.from * 1000)) {
        css += 'text-white bg-info'
        return css
      }
      return css
    }
  },
  computed: {
    now: function () {
      return new Date()
    }
  }
}

</script>

<style lang="scss">
</style>
