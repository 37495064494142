<template>
  <div>
    <div id="login-guide">
      <p class="text-lg-center">
        Hãy nhấn nút <b>Đăng nhập bằng Strava</b> để cùng tham gia với chúng tôi!
      </p>
      <p>
        <a href="#" class="btn btn-info" v-on:click="login">Đăng nhập bằng <b>Strava</b></a>
      </p>
      <div>
        <ul class="list-group text-lg-center">
          <li class="list-group-item">1. Nhấn nút <b>đăng nhập bằng Strava</b> như hình bên trên</li>
          <li class="list-group-item">
            <img class="w-50 h-50 img-thumbnail" src="../assets/login-guide-new.png">
          </li>
          <li class="list-group-item">2. Chọn <b>Authorize</b> để cho phép <b>nghienchaybo.com</b> đồng bộ dữ liệu <b>Strava</b> của bạn</li>
          <li class="list-group-item">3. Sau đó, dữ liệu từ <b>Strava</b> của bạn sẽ được đồng bộ tự động đến <b>nghienchaybo.com</b></li>
        </ul>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  name: 'login',
  metaInfo: {
    title: 'Login'
  },

  data () {
    return {
      resources: [],
      error: '',
      token: ''
    }
  },
  beforeMount () {
    if (/^\?fbclid=/.test(location.search)) {
      location.replace(location.href.replace(/\?fbclid.+/, ''))
    }
  },
  created () {
  },
  methods: {
    login (event) {
      event.target.className += ' disabled'
      let clientId = '45744' // todo: should be get from config file
      let stravaAuthUrl = 'http://www.strava.com/oauth/authorize' // todo: should be get from config file
      let redirectUri = window.location.origin + '/webhook?client=strava'
      let scope = 'read,activity:read'
      let authUrl = stravaAuthUrl + '?client_id=' + clientId + '&response_type=code&approval_prompt=force&scope=' + scope + '&redirect_uri=' + redirectUri
      window.location.href = authUrl
    }
  }
}

</script>

<style lang="scss">
</style>
