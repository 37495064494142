<template>
  <div id="nav">
      <nav class="navbar navbar-expand-lg navbar-light" v-bind:class="bgColor">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item active">
            <router-link class="nav-link" to="/" exact>Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/events" exact>Events</router-link>
          </li>
          <li class="nav-item">
            <a href="https://nghienchaybo.com/blog/2022/02/21/huong-dan-dang-nhap-nghien-chay-bo/" target="_blank" class="nav-link">Hướng dẫn đăng nhập</a>
          </li>
          <li class="nav-item">
            <router-link v-if="!isLoggedIn" class="nav-link" to="/login" exact>Login</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="isLoggedIn" class="nav-link" to="/profile" exact>Profile</router-link>
          </li>
          <li class="nav-item">
            <a href="#" v-if="isLoggedIn" class="nav-link" v-on:click="logout">Logout</a>
          </li>
          <li class="nav-item">
            <a href="https://nghienchaybo.com/blog/" target="_blank" class="nav-link">Blog</a>
          </li>
          <li class="nav-item">
            <a href="https://nghienchaybo.com/blog/2021/02/11/about-us/" target="_blank" class="nav-link">About us/ Giới thiệu</a>
          </li>
          <li class="nav-item">
            <a href="https://forms.gle/ZR35ZekK2WgqR8Au7" target="_blank" class="nav-link">Tạo giải chạy</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>

export default {
  name: 'NavBar',
  data: function () {
    return {
      bgColor: ''
    }
  },
  created () {
    this.bgColor = this.getBgColor()
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.isLoggedIn
    }
  },
  methods: {
    logout: function () {
      if (localStorage.hasOwnProperty('athlete')) {
        localStorage.removeItem('athlete')
      }
      window.location.reload()
    },
    getBgColor: function () {
      const env = process.env.NODE_ENV || 'dev'
      if (env === 'dev' || env === 'stag') {
        return 'bg-warning'
      }
      return ''
    }
  }
}
</script>
<style>
  #nav {
    text-align: left;
    padding: 60px;
    background: url("../assets/tet2024.jpeg") no-repeat center center;
    background-size: cover;
  }
  #nav ul {
    font-size: larger;
  }
  #nav a {
    color: #000000;
  }
  a:hover {
    cursor: pointer;
  }
  #nav a.router-link-exact-active {
    color: black;
  }
</style>
