import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'

import './filters'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faArrowLeft,
  faArrowRight,
  faSwimmer,
  faRunning,
  faVrCardboard,
  faBiking,
  faWalking,
  faCheck,
  faCloudSun,
  faHome,
  faStar,
  faSearch,
  faChartPie,
  faEye,
  faFlag,
  faPlus,
  faBars,
  faPuzzlePiece
} from '@fortawesome/free-solid-svg-icons'

import { BootstrapVue, IconsPlugin, ModalPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
library.add(
  faSwimmer,
  faBiking,
  faRunning,
  faVrCardboard,
  faWalking,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCloudSun,
  faHome,
  faStar,
  faSearch,
  faChartPie,
  faEye,
  faFlag,
  faPlus,
  faBars,
  faPuzzlePiece
)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Modal Plugin
Vue.use(ModalPlugin)
// Meta plugin
Vue.use(VueMeta)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
