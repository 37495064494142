<template>
    <div id="app">
      <main role="main">
        <NavBar/>
        <TopBanner/>
        <div class="album py-5 bg-light">
          <div class="container">
            <router-view/>
          </div>
        </div>
      </main>
      <footer class="text-muted">
        <div class="container">
          <p class="float-right">
            <a href="#">Back to top</a>
          </p>
            <p class="float-right">
              <router-link class="nav-link" to="/" exact>
                  <font-awesome-icon icon="home"/> Home
              </router-link>
            </p>
          <p class="text-sm-center sm">
            <b>affiliate sites: </b><br/>
            <a href="https://www.youtube.com/@logauit" target="_blank">👨💻 Học lập
              trình cùng Ahaxu 👨💻</a><br/>
            <a href="https://hapiicoffee.com.vn/collections/all?ref=lkaht" target="_blank">HapiiCoffee</a><br>
            <a href="https://3bforerunner.com?utm_source=nghienchaybo.com" target="_blank">🛍️3bforerunner🛍️</a><br>
          </p>
          <p>nghienchaybo.com | virtual sports | health tech - 2024 | powered by strava | in beta testing</p>
        </div>
      </footer>
    </div>
</template>

<script>
import NavBar from '@/views/NavBar'
import TopBanner from '@/views/TopBanner'
export default {
  components: { NavBar, TopBanner },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Keep Running'
    }
  },
  metaInfo: {
    title: 'Nghienchaybo.com - Keep Running'
  }
}
</script>

<style lang="scss">
  #app {
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
  }
</style>
