import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import ExchangeToken from './views/ExchangeToken'
import Profile from './views/Profile'
import Home from './views/Home'
import Events from './views/Events'
import Webhook from './views/Webhook'
import EventDetail from '@/views/EventDetail'
import EventDashboard from '@/views/EventDashboard'
import TeamEventDashboard from '@/views/TeamEventDashboard'
import RoundCounter from '@/views/RoundCounter'
import RoundCounterDashboard from '@/views/RoundCounterDashboard'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        'title': 'Home'
      }
    },
    {
      path: '/events',
      name: 'events',
      component: Events,
      meta: {
        'title': 'Events'
      }
    },
    {
      path: '/event/:slug',
      name: 'eventDetail',
      component: EventDetail,
      meta: {
        'title': 'Event Detail'
      }
    },
    {
      path: '/event-dashboard/:slug',
      name: 'eventDashboard',
      component: EventDashboard,
      meta: {
        'title': 'Event Dashboard'
      }
    },
    {
      path: '/team-event-dashboard/:slug',
      name: 'teamEventDashboard',
      component: TeamEventDashboard,
      meta: {
        'title': 'Team Event Dashboard'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        'title': 'Login'
      }
    },
    {
      path: '/webhook',
      name: 'webhook',
      component: Webhook
    },
    {
      path: '/exchange-token',
      name: 'exchangeToken',
      component: ExchangeToken
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        'title': 'Profile'
      }
    },
    {
      path: '/round-counter/:slug',
      name: 'roundCounter',
      component: RoundCounter,
      meta: {
        'title': 'RoundCounter'
      }
    },
    {
      path: '/round-counter-dashboard/:slug',
      name: 'roundCounterDashboard',
      component: RoundCounterDashboard,
      meta: {
        'title': 'RoundCounterDashboard'
      }
    }
  ]
})
