// Vue.js Filters
// https://vuejs.org/v2/guide/filters.html

import Vue from 'vue'

let filters = {
  formatTimestamp (timestamp) {
    let datetime = new Date(timestamp)
    return datetime.toLocaleDateString('en-US') + ' ' + datetime.toLocaleTimeString('en-US')
  },

  eventStatus (toTs) {
    let currentTs = Date.now()
    if (toTs < currentTs) {
      return 'expired event/ sự kiện đã kết thúc'
    }
    return ''
  },

  subStr (string) {
    return string.substring(0, 150) + '..'
  },
  subStrWithLength (string, length) {
    return string.substring(0, length) + '..'
  },
  formatNumber (input) {
    return Math.round(input)
  }

}

// Register All Filters on import
Object.keys(filters).forEach(function (filterName) {
  Vue.filter(filterName, filters[filterName])
})
